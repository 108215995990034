

































import {computed, defineComponent, PropType, ref} from "@vue/composition-api";
import {DebugInfoIndex} from "../../../../backend/packages/calc/result/logic/models";
import Helpers from "@/utils/helpers";
import DebugResultsView from "@/views/_components/DebugResultsView.vue";

export default defineComponent({
  components: {
    DebugResultsView
  },
  props: {
    total: { type: Number, required: true },
    plusCount: { type: Number, required: true },
    plusTexts: { type: Array as PropType<string[]>, required: true },
    debugTotals: Object as PropType<DebugInfoIndex>
  },
  setup(props, context) {
    const totalFormatted = computed(() => props.total + '%')

    const totalColorVariant = computed(() => {
      if (props.total >= 90) return "super"
      else if (props.total >= 75) return "success"
      else if (props.total >= 65) return "success-light"
      else if (props.total >= 55) return "average"
      else if (props.total >= 16) return "danger-light"
      else return "danger"
    })

    const plusTextsTooltip = computed(() => {
      let texts: string[] = []

      for (let i = 0; i < props.plusTexts.length; i++) {
        texts.push(`+ ${props.plusTexts[i]}`) // `
      }

      return '<b>Важные особенности:</b> <br/>' + texts.join('<br/>')
    })

    const isDebugResultsEnabled = ref<boolean>(false)

    if (process.env.NODE_ENV !== 'production') {
      console.log(props.debugTotals)
      if (Helpers.isExists(props.debugTotals)) {
        isDebugResultsEnabled.value = true
      }
    }

    return {
      totalFormatted,
      totalColorVariant,
      plusTextsTooltip,

      isDebugResultsEnabled
    }
  }
})
