import {ChakraType} from "../../../backend/packages/calc/result/logic/models";
import Helpers from "@/utils/helpers";

export default function useBiorhythmUtils() {
  const biorhythmSuperMinValue = 85
  const biorhythmCompatibleMinValue = 65
  const biorhythmDissonanceMaxExclValue = 16

  const getBiorhythmTotalColorVariant = (result: number) => {
    if (result >= 90) return "dark"
    else return getBiorhythmColorVariant(result)
  }
  const getBiorhythmColorVariant = (result: number) => {
    if (result >= biorhythmSuperMinValue) return "super"
    else if (result >= 75) return "success"
    else if (result >= biorhythmCompatibleMinValue) return "success-light"
    else if (result >= 55) return "average"
    else if (result >= biorhythmDissonanceMaxExclValue) return "danger-light"
    else return "danger"
  }

  const getCssVariableColorHex = (name: string) => {
    return getComputedStyle(document.body).getPropertyValue('--' + name)
  }
  const getBiorhythmColorHex = (result: number) => {
    if (result >= biorhythmSuperMinValue) return getCssVariableColorHex("super")
    else if (result >= 75) return getCssVariableColorHex("success")
    else if (result >= biorhythmCompatibleMinValue) return getCssVariableColorHex("success-light")
    else if (result >= 55) return getCssVariableColorHex("average")
    else if (result >= biorhythmDissonanceMaxExclValue) return getCssVariableColorHex("danger-light")
    else return getCssVariableColorHex("danger")
  }

  const getBiorhythmCompatibleText = (result: number) => {
    if (result >= biorhythmSuperMinValue) return "максимум"
    else if (result >= 75) return "почти совпадают"
    else if (result >= biorhythmCompatibleMinValue) return "совместимы"
    else if (result >= 55) return "пересекаются"
    else if (result >= biorhythmDissonanceMaxExclValue) return "не совместимы"
    else return "диссонанс"
  }

  const getBiorhythmChakraResultText = (type: ChakraType, result: number, skipQuotes = false) => {
    let text: string | null = null
    if (result >= biorhythmCompatibleMinValue) {
      switch (type) {
        case ChakraType.Physical:
          text = "Я хочу партнера"
          break
        case ChakraType.Emotional:
          text = "Мне нравится партнер"
          break
        case ChakraType.Intellectual:
          text = "Я согласен с партнером"
          break
        case ChakraType.Heart:
          text = "Партнер — мой лучший друг"
          break
        case ChakraType.Creative:
          text = "Партнер вдохновляет меня"
          break
        case ChakraType.Intuitive:
          text = "Партнер читает мои мысли"
          break
        case ChakraType.Higher:
          text = "У нас общий идеал"
          break
      }
    }
    
    if (Helpers.isNotEmpty(text)) {
      return skipQuotes ? text : `«${text}»` // `
    } else {
      return ""
    }
  }
  
  return {
    biorhythmSuperMinValue,
    biorhythmCompatibleMinValue,
    biorhythmDissonanceMaxExclValue,

    getBiorhythmTotalColorVariant,
    getBiorhythmColorVariant,
    getCssVariableColorHex,
    getBiorhythmColorHex,
    getBiorhythmCompatibleText,
    getBiorhythmChakraResultText,
  }
}