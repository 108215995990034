import {MatchResult} from "../../../../../backend/packages/calc/result/logic/models";

export type State = {
  isMatchResultLoading: boolean
  matchResult: MatchResult | null
}

export const state: State = {
  isMatchResultLoading: false,
  matchResult: null
};
