import { render, staticRenderFns } from "./BiorhythmResultView.vue?vue&type=template&id=15ac2c19&scoped=true&"
import script from "./BiorhythmResultView.vue?vue&type=script&lang=ts&"
export * from "./BiorhythmResultView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15ac2c19",
  null
  
)

export default component.exports