import { ZodiacSign, ZodiacSignElement} from "../../../backend/packages/calc/result/logic/models";

export default function useHoroscopeUtils() {
  const getZodiacSignName = (sign: ZodiacSign) => {
    switch (sign) {
      case ZodiacSign.Aries: return "Овен"
      case ZodiacSign.Taurus: return "Телец"
      case ZodiacSign.Gemini: return "Близнецы"
      case ZodiacSign.Cancer: return "Рак"
      case ZodiacSign.Leo: return "Лев"
      case ZodiacSign.Virgo: return "Дева"
      case ZodiacSign.Libra: return "Весы"
      case ZodiacSign.Scorpio: return "Скорпион"
      case ZodiacSign.Sagittarius: return "Стрелец"
      case ZodiacSign.Capricorn: return "Козерог"
      case ZodiacSign.Aquarius: return "Водолей"
      case ZodiacSign.Pisces: return "Рыбы"
    }
  }
  const getZodiacSignIconClass = (sign: ZodiacSign) => {
    switch (sign) {
      case ZodiacSign.Aries: return "zf-icon zf-aries"
      case ZodiacSign.Taurus: return "zf-icon zf-taurus"
      case ZodiacSign.Gemini: return "zf-icon zf-gemini"
      case ZodiacSign.Cancer: return "zf-icon zf-cancer"
      case ZodiacSign.Leo: return "zf-icon zf-leo"
      case ZodiacSign.Virgo: return "zf-icon zf-virgo"
      case ZodiacSign.Libra: return "zf-icon zf-libra"
      case ZodiacSign.Scorpio: return "zf-icon zf-scorpio"
      case ZodiacSign.Sagittarius: return "zf-icon zf-sagittarius"
      case ZodiacSign.Capricorn: return "zf-icon zf-capricorn"
      case ZodiacSign.Aquarius: return "zf-icon zf-aquarius"
      case ZodiacSign.Pisces: return "zf-icon zf-pisces"
    }
  }
  const getZodiacSignElementName = (element: ZodiacSignElement) => {
    switch (element) {
      case ZodiacSignElement.Fire: return "Огонь"
      case ZodiacSignElement.Earth: return "Земля"
      case ZodiacSignElement.Air: return "Воздух"
      case ZodiacSignElement.Water: return "Вода"
    }
  }
  
  return {
    getZodiacSignName,
    getZodiacSignIconClass,
    getZodiacSignElementName,
  }
}