

















































import {computed, defineComponent, PropType} from "@vue/composition-api";
import useHoroscopeUtils from "@/utils/useHoroscopeUtils";
import {HoroscopeResult} from "../../../../backend/packages/calc/result/logic/models";
import ZodiacSignResultView from "@/views/_components/ZodiacSignResultView.vue";
import AppTooltip from "@/views/_components/AppTooltip.vue";
import Helpers from "@/utils/helpers";

export default defineComponent({
  components: {
    AppTooltip,
    ZodiacSignResultView,
  },
  props: {
    horoscope: { type: Object as PropType<HoroscopeResult>, required: true }
  },
  setup() {
    const { getZodiacSignIconClass } = useHoroscopeUtils()

    const wrapInQuotes = (text: string) => Helpers.isNotEmpty(text) ? `«${text}»` : '' // `

    return {
      getZodiacSignIconClass,
      wrapInQuotes
    }
  }
})
