






























import {computed, defineComponent, ref} from "@vue/composition-api";
import useStore from "@/store";
import {UiMutationTypes} from "@/store/modules/ui/types";

export default defineComponent({
  setup(props, context) {
    const store = useStore()

    const isDarkMode = computed(() => store.getters.isDarkMode)
    const versionTooltip = computed(() => 'v' + APP_VERSION)
    const contactLink = computed(() => 'mailto:' + CONTACT_EMAIL)

    const toggleDarkMode = () => {
      store.commit(UiMutationTypes.SET_FORCE_DARK_MODE, !isDarkMode.value)
    }

    return {
      isDarkMode,
      versionTooltip,
      contactLink,

      toggleDarkMode,
    }
  },
})
