import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import FullPageLayout from "@/views/FullPageLayout.vue";

import HomePage from '@/views/home/Home.vue'

const routes: RouteConfig[] = [
  {
    path: '',
    component: FullPageLayout,
    children: [
      {
        path: '/',
        name: 'home',
        component: HomePage
      },
    ]
  },
  // Redirect to 404 page, if no match found
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export function useRouter() {
  return router;
}