






























import {computed, defineComponent, PropType} from "@vue/composition-api";
import ChakraInfoView from "@/views/_components/ChakraInfoView.vue";
import {BiorhythmResult} from "../../../../backend/packages/calc/result/logic/models";
import useBiorhythmUtils from "@/utils/useBiorhythmUtils";
import Helpers from "@/utils/helpers";
import AppTooltip from "@/views/_components/AppTooltip.vue";

export default defineComponent({
  components: {
    AppTooltip,
    ChakraInfoView
  },
  props: {
    biorhythm: { type: Object as PropType<BiorhythmResult>, required: true }
  },
  setup(props, context) {
    const { getBiorhythmTotalColorVariant } = useBiorhythmUtils()

    return {
      helpers: Helpers,

      getBiorhythmTotalColorVariant,
    }
  }
})
