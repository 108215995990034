import {CharacterTraitGoldenMeanCount} from "../../../backend/packages/calc/result/logic/pythagorean-square";

export default function usePythagoreanSquareUtils() {
  const isCharacterGoldenMean = (digitCount: number) => digitCount === CharacterTraitGoldenMeanCount

  const isDifferencePositive = (difference: number) => {
    if (difference <= 2) return true
    else if (difference >= 4) return false
    else return null
  }
  
  return {
    isCharacterGoldenMean,
    isDifferencePositive,
  }
}