import { GetterTree } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import Helpers from "@/utils/helpers";

export const LocalStorageDarkModeKey = "mm:darkMode";

export type Getters = {
  isDarkMode(state: State): boolean
}

let isSystemDarkModeEnabled: boolean | null = null

export const getters: GetterTree<State, RootState> & Getters = {
  isDarkMode(state) {
    if (Helpers.isBoolean(state.isDarkModeForced)) return state.isDarkModeForced!

    const localStorageDarkModeString = localStorage.getItem(LocalStorageDarkModeKey);

    if (Helpers.isNotEmpty(localStorageDarkModeString)) {
      const localStorageDarkMode = JSON.parse(localStorageDarkModeString!)

      if (Helpers.isBoolean(localStorageDarkMode)) return localStorageDarkMode
    }

    if (Helpers.isBoolean(isSystemDarkModeEnabled)) {
      return isSystemDarkModeEnabled!
    } else {
      isSystemDarkModeEnabled = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      return isSystemDarkModeEnabled!
    }
  }
};
