import { ActionTree } from 'vuex';

import {_AugmentedActionContext, RootState} from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import {Getters} from "./getters";
import {ContextActionTypes, ContextMutationTypes} from "@/store/modules/context/types";
import Helpers from "@/utils/helpers";
import {ApiResponse, MatchResult} from "../../../../../backend/packages/calc/result/logic/models";

export interface CalcMatchResultPayload {
  date: Date

  // system
  _pairKey?: string
  _mainDate?: Date
}

type AugmentedActionContext = _AugmentedActionContext<State, Actions, Mutations, Getters>;

export interface Actions {
  [ContextActionTypes.CALC_MATCH_RESULT]({ commit, dispatch }: AugmentedActionContext,
                                         payload: CalcMatchResultPayload): Promise<boolean>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ContextActionTypes.CALC_MATCH_RESULT]({ state, commit, dispatch }, payload: CalcMatchResultPayload) {
    return new Promise<boolean>(async (resolve, reject) => {
      if (!Helpers.isExists(payload.date)) {
        resolve(false);
        return
      }

      commit(ContextMutationTypes.MATCH_RESULT_LOADING, true);

      const dateUtc = Helpers.convertToUtcDate(payload.date)!

      if (process.env.NODE_ENV !== 'production') {
        const calcImport = await import("../../../../../backend/packages/calc/result/logic/calc")

        await new Promise(r => setTimeout(r, 200))

        if (!state.isMatchResultLoading) {
          resolve(false);
          return
        }

        let mainDateUtc: Date | null
        if (Helpers.isNotEmpty(payload._pairKey) && Helpers.isExists(payload._mainDate)) {
          mainDateUtc = Helpers.convertToUtcDate(payload._mainDate)!
        } else {
          mainDateUtc = Helpers.createUtcDate(1988, 9, 22)
        }

        const matchResult = calcImport.calculateMatchResult(mainDateUtc, dateUtc, false)

        console.log("MATCH_RESULT", matchResult, mainDateUtc.toISOString(), dateUtc.toISOString())

        commit(ContextMutationTypes.SET_MATCH_RESULT, matchResult);
      } else {
        let params = new URLSearchParams();

        params.append('date', dateUtc.toISOString())

        if (Helpers.isNotEmpty(payload._pairKey) && Helpers.isExists(payload._mainDate)) {
          params.append('pairKey', payload._pairKey!)
          params.append('mainDate', Helpers.convertToUtcDate(payload._mainDate)!.toISOString())
        }

        const calcResultResponse = await fetch(DO_FUNCTIONS_ENDPOINT_ROOT + '/calc/result?' + params.toString(), {
          method: 'GET',
        });

        const calcResultJson = await calcResultResponse.json() as ApiResponse<MatchResult>

        if (!state.isMatchResultLoading) {
          resolve(false);
          return
        }

        if (Helpers.isExists(calcResultJson) && calcResultJson.success) {
          commit(ContextMutationTypes.SET_MATCH_RESULT, calcResultJson.response);
        } else {
          console.error("Server Error:" + calcResultJson?.error)
          resolve(false);
        }
      }

      commit(ContextMutationTypes.MATCH_RESULT_LOADING, false);
      resolve(true);
    });
  },
};