























import {computed, defineComponent, nextTick, PropType, Ref, ref, watch} from "@vue/composition-api";
import vSelect from 'vue-select';
import Helpers from "@/utils/helpers";
import dayjs from "dayjs";

interface SelectItem {
  value: number
  text: string
}

export default defineComponent({
  components: {
    vSelect,
  },
  props: {
    value: Date,
    disabled: Boolean
  },
  setup(props, context) {
    const innerValue = computed({
      get() {
        return props.value;
      },
      set(val) {
        context.emit('input', val);
      }
    });

    const startYear = 1985
    const endYear = 2001

    const selectedDay = ref<number>(1)
    const selectedMonth = ref<number>(0)
    const selectedYear = ref<number>(1990)

    const maxDaysInMonth = ref<number>(31)
    const months = ref<SelectItem[]>([])
    const years = ref<SelectItem[]>([])

    const onSelectedDayChanged = (val: number | null) => {
      const newValue = Helpers.isNumeric(val) ? val! : 0

      nextTick(() => {
        selectedDay.value = Math.max(1, Math.min(newValue, maxDaysInMonth.value))
      })
    }

    const updateData = () => {
      let monthsList: SelectItem[] = []

      for (let i = 0; i <= 11; i++) {
        monthsList.push({
          value: i,
          text: `${dayjs().month(i).format("MMMM")} (${i + 1})` // `
        })
      }

      months.value = monthsList

      let yearsList: SelectItem[] = []

      for (let i = startYear; i <= endYear; i++) {
        yearsList.push({
          value: i,
          text: i.toString()
        })
      }
      years.value = yearsList
    }

    watch(() => [selectedMonth.value, selectedYear.value], () => {
      maxDaysInMonth.value = dayjs.utc(Helpers.createUtcDate(selectedYear.value, selectedMonth.value, 1)).daysInMonth()

      if (selectedDay.value > maxDaysInMonth.value) {
        selectedDay.value = maxDaysInMonth.value
      }
    }, { immediate: true })

    watch(() => [selectedDay.value, selectedMonth.value, selectedYear.value], () => {
      const result = dayjs.utc(Helpers.createUtcDate(selectedYear.value, selectedMonth.value, selectedDay.value))

      innerValue.value = result.isValid() ? result.toDate() : null
    }, { immediate: true })

    updateData()

    return {
      helpers: Helpers,

      selectedDay,
      selectedMonth,
      selectedYear,

      onSelectedDayChanged,

      maxDaysInMonth,
      months,
      years,
    }
  }
})
