








import {computed, defineComponent, nextTick, onBeforeUnmount, onMounted, watch} from "@vue/composition-api";
import cssVars from "css-vars-ponyfill";
import classNames from "classnames/dedupe";
import AppFooter from "@/views/_components/AppFooter.vue";
import useStore from "@/store";
import {UiMutationTypes} from "@/store/modules/ui/types";

export default defineComponent({
  components: {
    AppFooter
  },
  setup() {
    const store = useStore()

    const hasCSSVarSupport = typeof window !== 'undefined' && window.CSS && window.CSS.supports && window.CSS.supports('(--a: 0)');

    const tryUpdateWindowSize = (isInit: boolean) => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;

      if (store.state.ui.windowWidth !== newWidth || store.state.ui.windowHeight !== newHeight) {
        store.commit(UiMutationTypes.UPDATE_WINDOW_SIZE, {
          width: newWidth,
          height: newHeight
        });

        let vh = newHeight * 0.01;

        // required for mobile
        if (isInit) {
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        }

        changeVhVariable(vh, () => {
          // AfterWindowResizeCompleted
        });
      }
    }

    // https://github.com/jhildenbiddle/css-vars-ponyfill/issues/93
    const changeVhVariable = (vh: number, afterChangeCb: () => void) => {
      cssVars({
        variables: { '--vh': `${vh}px` },
        // Legacy browsers
        onComplete: (cssText, styleElms, cssVariables, benchmark) => {
          if (afterChangeCb) {
            afterChangeCb();
          }
        }
      });

      // Modern browsers
      if (hasCSSVarSupport) {
        if (afterChangeCb) {
          afterChangeCb();
        }
      }
    }

    const handleWindowResize = () => {
      tryUpdateWindowSize(false);
    }

    const updateBodyClasses = () => {
      document.body.className = classNames(document.body.className, {
        "dark-mode": store.getters.isDarkMode,
      });
    }

    watch(() => [store.getters.isDarkMode], updateBodyClasses)

    // init
    updateBodyClasses()

    if (process.env.NODE_ENV === 'production') {
      // warm function
      fetch(DO_FUNCTIONS_ENDPOINT_ROOT + '/calc/result?ping=1', {
        method: 'GET',
      })
    }

    onMounted(() => {
      nextTick(() => {
        window.addEventListener('resize', handleWindowResize);
      });

      // init
      tryUpdateWindowSize(true);
    });

    onBeforeUnmount(() => {
      // global event
      window.removeEventListener('resize', handleWindowResize);
    })

    return {
    }
  }
})
