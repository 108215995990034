
export interface ApiResponse<T> {
  success: boolean
  error: string | null
  response: T | null
}

export interface DebugInfoIndex {
  [key: string]: DebugInfo
}

export interface DebugInfo {
  name: string

  result: number
}

//#region Biorhythm

export enum ChakraType {
  Physical,
  Emotional,
  Intellectual,
  Heart,
  Creative,
  Intuitive,
  Higher,
}

export enum ChakraClass {
  Male,
  Female,
  Higher,
}

export interface ChakraInfo {
  type: ChakraType
  displayName: string
  description: string
  class: ChakraClass
  isHigher: boolean
  
  result: number

  // debug only
  _debugResults?: DebugInfoIndex
}

export interface BiorhythmResult {
  physical: ChakraInfo
  emotional: ChakraInfo
  intellectual: ChakraInfo
  heart: ChakraInfo
  
  creative: ChakraInfo
  intuitive: ChakraInfo
  higher: ChakraInfo

  isNegativeDuplication: boolean
  totalResult: number
  totalResultText: string | null

  // debug only
  _debugResults?: DebugInfoIndex
}

//#endregion

//#region Zodiac

export enum ZodiacSign {
  // Овен
  Aries = 1,
  // Телец
  Taurus = 2,
  // Близнецы
  Gemini = 3,
  // Рак
  Cancer = 4,
  // Лев
  Leo = 5,
  // Дева
  Virgo = 6,
  // Весы
  Libra = 7,
  // Скорпион
  Scorpio = 8,
  // Стрелец
  Sagittarius = 9,
  // Козерог
  Capricorn = 10,
  // Водолей
  Aquarius = 11,
  // Рыбы
  Pisces = 12,
}

export enum ZodiacSignElement {
  Fire = 1,
  Earth = 2,
  Air = 3,
  Water = 4
}

export interface ZodiacSignResult {
  sign: ZodiacSign
  element: ZodiacSignElement
}

export interface ZodiacSignPairResult {
  differenceNumber: number
  differenceNumberAbs: number
  
  title: string
  description: string

  maleRoleTitle: string | null
  femaleRoleTitle: string | null
  
  isCompatible: boolean,
  resultText: string
}

export interface HoroscopeResult {
  male: ZodiacSignResult
  female: ZodiacSignResult
  
  pair: ZodiacSignPairResult
}

//#endregion

//#region Pythagorean Square

export interface PythagoreanSquareTraitInfo {
  isMain: boolean
  digits: number[]
  digitCount: number
  
  title: string
  description: string
}

export interface PythagoreanSquareResult {
  // Характер, сила воли (кол-во 1)
  character: PythagoreanSquareTraitInfo
  // Энергетика, харизма (кол-во 2)
  energy: PythagoreanSquareTraitInfo
  // Познание, творчество (кол-во 3)
  interests: PythagoreanSquareTraitInfo
  // Здоровье, красота (кол-во 4)
  health: PythagoreanSquareTraitInfo
  // Логика, интуиция (кол-во 5)
  logic: PythagoreanSquareTraitInfo
  // Трудолюбие, мастерство (кол-во 6)
  labor: PythagoreanSquareTraitInfo
  // Удача, везение (кол-во 7)
  luck: PythagoreanSquareTraitInfo
  // Чувство долга (кол-во 8)
  duty: PythagoreanSquareTraitInfo
  // Память, ум (кол-во 9)
  memory: PythagoreanSquareTraitInfo
  
  // Дополнительные
  //
  // Самооценка (столбец «1-2-3»)
  selfEsteem: PythagoreanSquareTraitInfo
  // Зарабатывание денег (столбец «4-5-6»)
  work: PythagoreanSquareTraitInfo
  // Потенциал таланта (столбец «7-8-9»)
  talent: PythagoreanSquareTraitInfo
  // Целеустремленность (строка «1-4-7»)
  purpose: PythagoreanSquareTraitInfo
  // Семейность (строка «2-5-8»)
  family: PythagoreanSquareTraitInfo
  // Стабильность (строка «3-6-9»)
  habits: PythagoreanSquareTraitInfo
  // Духовный потенциал (диагональ «1-5-9»)
  spirit: PythagoreanSquareTraitInfo
  // Темперамент (диагональ «3-5-7»)
  temperament: PythagoreanSquareTraitInfo
}

export interface PythagoreanSquarePairResult {
  male: PythagoreanSquareResult
  female: PythagoreanSquareResult
  
  characterDigitCountDifference: number,
  energyDigitCountDifference: number,
  interestsDigitCountDifference: number,
  healthDigitCountDifference: number,
  logicDigitCountDifference: number,
  laborDigitCountDifference: number,
  luckDigitCountDifference: number,
  dutyDigitCountDifference: number,
  memoryDigitCountDifference: number,
  
  selfEsteemDigitCountDifference: number,
  workDigitCountDifference: number,
  talentDigitCountDifference: number,
  purposeDigitCountDifference: number,
  familyDigitCountDifference: number,
  habitsDigitCountDifference: number,
  spiritDigitCountDifference: number,
  temperamentDigitCountDifference: number,
}

//#endregion

export interface MatchResult {
  biorhythm: BiorhythmResult
  horoscope: HoroscopeResult
  pythagoreanSquare: PythagoreanSquarePairResult

  total: number
  plusCount: number
  plusTexts: string[]

  // debug only
  _debugTotals?: DebugInfoIndex
}