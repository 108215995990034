




















































































import {ref, computed, defineComponent, watch} from "@vue/composition-api";
import dayjs from "dayjs";
import TransitionExpand from "@/views/_transitions/TransitionExpand.vue";
import FullDateSelectControl from "@/views/_components/FullDateSelectControl.vue";
import Helpers from "@/utils/helpers";
import BiorhythmResultView from "@/views/home/BiorhythmResultView.vue";
import HoroscopeResultView from "@/views/home/HoroscopeResultView.vue";
import PythagoreanSquareResultView from "@/views/home/PythagoreanSquareResultView.vue";
import TotalResultView from "@/views/home/TotalResultView.vue";
import useStore from "@/store";
import {ContextActionTypes, ContextMutationTypes} from "@/store/modules/context/types";
import {useRouter} from "@/router";
import {MatchResult} from "../../../../backend/packages/calc/result/logic/models";

export default defineComponent({
  components: {
    TransitionExpand,
    FullDateSelectControl,
    BiorhythmResultView,
    HoroscopeResultView,
    PythagoreanSquareResultView,
    TotalResultView,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const pairParamKey = router.currentRoute.query.pair as string | null | undefined

    const matchResult = computed(() => store.state.context.matchResult)
    const isMatchResultLoading = computed(() => store.state.context.isMatchResultLoading)
    const isFirstResultCalculated = ref(false)

    const isPairMode = computed(() => Helpers.isNotEmpty(pairParamKey))

    const partnerDate = ref<Date | null>(null)
    const pairMainDate = ref<Date | null>(null)

    const calculateResult = async () => {
      const partnerDateJs = dayjs(<Date>partnerDate.value!)

      if (!partnerDateJs.isValid()) return

      if (isPairMode.value) {
        const pairMainDateJs = dayjs(<Date>pairMainDate.value!)

        if (!pairMainDateJs.isValid()) return

        await store.dispatch(ContextActionTypes.CALC_MATCH_RESULT, {
          date: partnerDateJs.toDate(),

          _pairKey: pairParamKey!,
          _mainDate: pairMainDateJs.toDate()
        })
      } else {
        await store.dispatch(ContextActionTypes.CALC_MATCH_RESULT, {
          date: partnerDateJs.toDate(),
        })
      }
    }

    watch(() => [store.state.context.matchResult], () => {
      if (Helpers.isExists(store.state.context.matchResult)) {
        isFirstResultCalculated.value = true
      }
    })
    watch(() => [partnerDate.value, pairMainDate.value], () => {
      store.commit(ContextMutationTypes.MATCH_RESULT_LOADING, false)
      store.commit(ContextMutationTypes.SET_MATCH_RESULT, null)
    })

    return {
      helpers: Helpers,

      isPairMode,

      matchResult: matchResult as unknown as MatchResult,
      isMatchResultLoading,
      isFirstResultCalculated,

      partnerDate,
      pairMainDate,

      calculateResult,
    }
  }
})
