
export class HelpersModel {
    // Value is exist
    isExists(value: any): boolean {
        return typeof value !== "undefined"
            && value !== null;
    }

    isObject(value: any): boolean {
        return this.isExists(value) && typeof value === "object";
    }

    isFunction(value: any): boolean {
        return this.isExists(value) && typeof value === "function";
    }

    toNumeric(value: any): number {
        return parseFloat(value);
    }

    isNumeric(value: any): boolean {
        return this.isExists(value) && !this.isArray(value) && (value - parseFloat(value) + 1) >= 0;
    }

    isBoolean(value: any): boolean {
        return typeof value === "boolean";
    }

    // Value is exist and not empty string
    isNotEmpty(value: any): boolean {
        return this.isExists(value) && value !== "";
    }
    // Contains
    stringContains(str: string, value: string): boolean {
        return str.indexOf(value) > -1;
    }
    roundDecimal(num: number, decimals: number) {
        return parseFloat((Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals)).toFixed(decimals));
    }

    // Format String
    formatString(formatString: string, ...args: string[]) {
        let s = formatString;
        for (let i = 0; i < args.length; i++) {
            let reg = new RegExp("\\{" + i + "\\}", "gm");
            s = s.replace(reg, args[i]);
        }

        return s;
    }

    // Pad (leading zeros)
    pad(num: number, size: number) {
        let s = num + "";
        while (s.length < size) s = "0" + s;
        return s;
    }

    // Deep object utils
    isDeepEquals(val1: any, val2: any, advancedCheck = false) {
        if (advancedCheck) {
            throw new Error("[isDeepEquals] advancedCheck === true is not implemented");
        } else {
            return JSON.stringify(val1) === JSON.stringify(val2);
        }
    }
    deepCopy(val: any) {
        return JSON.parse(JSON.stringify(val))
    }
    // https://stackoverflow.com/a/6842900/8311719
    deepSet(obj: any, value: any, path: string) {
        let i;
        let pathParts = path.split('.');
        for (i = 0; i < pathParts.length - 1; i++)
            obj = obj[pathParts[i]];

        obj[pathParts[i]] = value;
    }

    // Date
    createUtcDate(fullYear: number, month: number, date: number) {
        return new Date(Date.UTC(fullYear, month, date))
    }
    convertToUtcDate(date: Date | null | undefined) {
        if (!this.isExists(date)) return null

        return new Date(Date.UTC(date!.getFullYear(), date!.getMonth(), date!.getDate()))
    }

    // Array
    //
    // Check is array
    isArray(value: any): boolean {
        return Array.isArray ? Array.isArray(value) : value instanceof Array;
    }
    isNotEmptyArray(value: any): boolean {
        return this.isExists(value) && this.isArray(value) && value!.length > 0;
    }
    anyInArray<T>(array: T[], funcForFind: (item: T, index?: number) => boolean) {
        for (let i = 0; i < array.length; i++) {
            if (funcForFind(array[i], i)) {
                return true;
            }
        }
        return false;
    }
    findFirstInArray<T>(array: T[], funcForFind: (item: T, index?: number) => boolean) {
        for (let i = 0; i < array.length; i++) {
            if (funcForFind(array[i], i)) {
                return array[i];
            }
        }
        return null;
    }
    findInArray<T>(array: T[], funcForFind: (item: T, index?: number) => boolean) {
        let items: T[] = [];
        for (let i = 0; i < array.length; i++) {
            if (funcForFind(array[i], i)) {
                items.push(array[i]);
            }
        }
        return items;
    }
    findIndexInArray<T>(array: T[], funcForFind: (item: T, index?: number) => boolean): number | null {
        for (let i = 0; i < array.length; i++) {
            if (funcForFind(array[i], i)) {
                return i;
            }
        }
        return null;
    }
    // Remove in array
    removeInArray<T>(array: T[], funcForFind: (item: T, index?: number) => boolean) {
        if (!this.isArray(array)) return;

        for (let i = 0; i < array.length; i++) {
            if (funcForFind(array[i], i)) {
                array.splice(i, 1);
            }
        }
    }
    // Move in array
    moveInArray<T>(array: T[], fromIndex: number, toIndex: number) {
        if (!this.isArray(array)) return;

        let element = array[fromIndex];

        array.splice(fromIndex, 1);
        array.splice(toIndex, 0, element);
    }

    // DOM
    //
    domClosest(el: HTMLElement | null, selector: string) {
        if (el == null) return null;

        let matchesFn: string | null = null;

        // find vendor prefix
        ['matches','webkitMatchesSelector','mozMatchesSelector','msMatchesSelector','oMatchesSelector'].some(function(fn) {
            if (typeof (<any>document.body)[fn] == 'function') {
                matchesFn = fn;
                return true;
            }
            return false;
        });

        if (matchesFn == null) return null;

        let parent;

        // traverse parents
        while (el) {
            parent = el.parentElement;
            if (parent && (<any>parent)[matchesFn](selector)) {
                return parent;
            }
            el = parent;
        }

        return null;
    }

    // Sort
    //
    sortStrings<T>(array: T[], funcForCompare: (item: T) => string, desc: boolean = false) {
        array.sort((a, b) => {
            if (desc) {
                return ('' + funcForCompare(b)).localeCompare(funcForCompare(a));
            } else {
                return ('' + funcForCompare(a)).localeCompare(funcForCompare(b));
            }
        })
    }

    // Group
    //
    groupBy<T>(array: T[], funcForGroup: (item: T) => any): { [groupName: string] : T[] } {
        let key;
        return array.reduce((reduce, item) => {
            key = funcForGroup(item);

            reduce[key] = reduce[key] || [];
            reduce[key].push(item);

            return reduce;
        }, Object.create(null));
    }

    // Animation
    //
    animateScroll(element: HTMLElement, scrollTop: number, scrollLeft: number) {
        const maxScrollTop = element.scrollHeight - element.clientHeight;
        const maxScrollLeft = element.scrollWidth - element.clientWidth;
        const targetScrollTop = this.roundDecimal(this.clamp(scrollTop, 0, maxScrollTop), 0);
        const targetScrollLeft = this.roundDecimal(this.clamp(scrollLeft, 0, maxScrollLeft), 0);

        return new Promise((resolve) => {
            const scrollListener = (evt: any) => {
                if (typeof evt === 'undefined') {
                    return;
                }

                const target = evt.currentTarget;

                if (target.scrollTop === targetScrollTop && target.scrollLeft === targetScrollLeft) {
                    target.removeEventListener('scroll', scrollListener);
                    resolve(true);
                }
            };

            if (maxScrollTop > 0 || maxScrollLeft > 0) {
                if (targetScrollTop !== element.scrollTop || targetScrollLeft !== element.scrollLeft) {
                    element.addEventListener('scroll', scrollListener);

                    element.scroll({
                        top: targetScrollTop,
                        left: targetScrollLeft,
                        behavior: 'smooth'
                    });
                } else {
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        });
    }
    animateScrollAlt(element: HTMLElement, scrollTop: number, scrollLeft: number, duration: number): () => void {
        let canceled = false;
        let durationLeft = duration;

        const scrollTopParsed = this.roundDecimal(scrollTop, 0);
        const scrollLeftParsed = this.roundDecimal(scrollLeft, 0);

        const promise = new Promise((resolve, reject) => {
            const loop = () => {
                if (canceled || durationLeft <= 0) {
                    resolve(false);
                    return;
                }

                let topDiff = scrollTopParsed - element.scrollTop;
                let leftDiff = scrollLeftParsed - element.scrollLeft;

                const tick = 10;
                let topPerTick = topDiff / durationLeft * tick;
                let leftPerTick = leftDiff / durationLeft * tick;

                setTimeout(() => {
                    element.scrollTop = element.scrollTop + topPerTick;
                    element.scrollLeft = element.scrollLeft + leftPerTick;

                    if (element.scrollTop === scrollTopParsed && element.scrollLeft === scrollLeftParsed) {
                        resolve(true);
                        return;
                    }

                    durationLeft = durationLeft - tick;

                    loop();
                }, tick);
            };

            loop();
        });

        return () => {
            canceled = true;
        }
    }

    // Mobile
    //
    orientationChanged() {
        const timeout = 120;
        return new window.Promise((resolve) => {
            const go = (i: number, height0: number) => {
                window.innerHeight != height0 || i >= timeout ?
                    resolve(undefined) :
                    window.requestAnimationFrame(() => go(i + 1, height0));
            };
            go(0, window.innerHeight);
        });
    }

    // Math
    //
    // Get a value between two values
    clamp(value: number, min: number, max: number) {
        if (value < min) {
            return min;
        }
        else if (value > max) {
            return max;
        }

        return value;
    }
    // Get the linear interpolation between two value
    lerp(value1: number, value2: number, amount: number) {
        amount = amount < 0 ? 0 : amount;
        amount = amount > 1 ? 1 : amount;
        return value1 + (value2 - value1) * amount;
    }
    // Calculates the linear parameter t that produces the interpolant value within the range [a, b].
    inverseLerp(a: number, b: number, value: number) {
        return (value - a) / (b - a);
    }
}

const h = new HelpersModel();
export default h;
