











import {computed, defineComponent, PropType} from "@vue/composition-api";
import {DebugInfoIndex} from "../../../../backend/packages/calc/result/logic/models";
import Helpers from "@/utils/helpers";

export default defineComponent({
  props: {
    debugResults: { type: Object as PropType<DebugInfoIndex>, required: true }
  },
  setup(props, context) {
    const results = computed(() => Helpers.isExists(props.debugResults)
        ? Object.values(props.debugResults)
        : [])

    return {
      results
    }
  },
})
