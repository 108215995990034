





























import {computed, defineComponent, PropType} from "@vue/composition-api";
import { PythagoreanSquarePairResult} from "../../../../backend/packages/calc/result/logic/models";
import PythagoreanSquareTraitPairView from "@/views/_components/PythagoreanSquareTraitPairView.vue";
import AppTooltip from "@/views/_components/AppTooltip.vue";
import usePythagoreanSquareUtils from "@/utils/usePythagoreanSquareUtils";

export default defineComponent({
  components: {
    AppTooltip,
    PythagoreanSquareTraitPairView,
  },
  props: {
    pythagoreanSquare: { type: Object as PropType<PythagoreanSquarePairResult>, required: true }
  },
  setup(props, context) {
    const { isCharacterGoldenMean, isDifferencePositive } = usePythagoreanSquareUtils()

    const isCharacterDifferencePositive = computed(() =>
        isDifferencePositive(props.pythagoreanSquare.characterDigitCountDifference))
    const isFamilyDifferencePositive = computed(() =>
        isDifferencePositive(props.pythagoreanSquare.familyDigitCountDifference))
    const isTemperamentDifferencePositive = computed(() =>
        isDifferencePositive(props.pythagoreanSquare.temperamentDigitCountDifference))

    return {
      isCharacterDifferencePositive,
      isFamilyDifferencePositive,
      isTemperamentDifferencePositive,
    }
  }
})
