// VueCompositionAPI
import Vue from "vue";
import VueCompositionAPI from "@vue/composition-api";
Vue.use(VueCompositionAPI);

// App Styles
import '@/assets/scss/styles.scss'

// general imports
import {createApp} from "@vue/composition-api";
import App from './App.vue'
import {useRouter} from "@/router";
import useStore, { RootState} from "@/store";

const app = createApp(App);
const router = useRouter()

// Portal Vue
import PortalVue from 'portal-vue'
app.use(PortalVue);

// Bootstrap Vue
// main
import BootstrapVue from 'bootstrap-vue'
app.use(BootstrapVue);

// DayJS
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import 'dayjs/locale/ru'
dayjs.locale('ru');

// todo disabled for now
// Google Analytics
// import VueGtag from "vue-gtag";
// Vue.use(VueGtag, {
//   enabled: process.env.NODE_ENV === 'production',
//   config: { id: GA_MEASUREMENT_ID }
// }, router);

Vue.config.productionTip = false;

// @ts-ignore
window.Vue = new Vue({ router: router, store: useStore(), render: h => h(App) }).$mount('#app');