import { MutationTree } from 'vuex';

import { State } from './state';
import { UiMutationTypes } from './types';
import Helpers from "@/utils/helpers";
import {LocalStorageDarkModeKey} from "@/store/modules/ui/getters";

export interface UpdateWindowSizePayload {
  width: number | null
  height: number | null
}

export type Mutations<S = State> = {
  [UiMutationTypes.SET_FORCE_DARK_MODE](state: S, payload: boolean): void;
  [UiMutationTypes.UPDATE_WINDOW_SIZE](state: S, payload: UpdateWindowSizePayload): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [UiMutationTypes.SET_FORCE_DARK_MODE](state, isDarkMode) {
    state.isDarkModeForced = isDarkMode;
    localStorage.setItem(LocalStorageDarkModeKey, JSON.stringify(state.isDarkModeForced));
  },
  [UiMutationTypes.UPDATE_WINDOW_SIZE](state, payload) {
    state.windowWidth = payload.width;
    state.windowHeight = payload.height;
  }
};
