










import {computed, defineComponent} from "@vue/composition-api";
import AppFooter from "@/views/_components/AppFooter.vue";

export default defineComponent({
  components: {
    AppFooter
  },
  setup() {
  }
})
