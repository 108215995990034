export type State = {
  isDarkModeForced: boolean | null
  
  windowWidth: number | null,
  windowHeight: number | null
}

export const state: State = {
  isDarkModeForced: null,
  
  windowWidth: null,
  windowHeight: null,
};
