






































import {computed, defineComponent, PropType} from "@vue/composition-api";
import {PythagoreanSquareResult, PythagoreanSquareTraitInfo, ZodiacSignResult} from "../../../../backend/packages/calc/result/logic/models";
import Helpers from "@/utils/helpers";
import AppTooltip from "@/views/_components/AppTooltip.vue";

export default defineComponent({
  components: {AppTooltip},
  props: {
    label: { type: String, required: true },
    maleTrait: { type: Object as PropType<PythagoreanSquareTraitInfo>, required: true },
    femaleTrait: { type: Object as PropType<PythagoreanSquareTraitInfo>, required: true },
    positiveDifference: { type: Boolean as PropType<boolean | null> }
  },
  setup() {
    return {
      helpers: Helpers,
    }
  }
})
