import { MutationTree } from 'vuex';

import { State } from './state';
import { ContextMutationTypes } from './types';
import {MatchResult} from "../../../../../backend/packages/calc/result/logic/models";

export type Mutations<S = State> = {
  [ContextMutationTypes.MATCH_RESULT_LOADING](state: S, payload: boolean): void;
  [ContextMutationTypes.SET_MATCH_RESULT](state: S, payload: MatchResult | null | undefined): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [ContextMutationTypes.MATCH_RESULT_LOADING](state, isLoading) {
    state.isMatchResultLoading = isLoading
  },
  [ContextMutationTypes.SET_MATCH_RESULT](state, result) {
    state.matchResult = result ?? null
  }
};
