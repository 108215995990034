





















































import {computed, defineComponent, nextTick, PropType, Ref, ref, watch} from "@vue/composition-api";
import Helpers from "@/utils/helpers";
import {ChakraClass, ChakraInfo} from "../../../../backend/packages/calc/result/logic/models";
import useBiorhythmUtils from "@/utils/useBiorhythmUtils";
import AppTooltip from "@/views/_components/AppTooltip.vue";
// @ts-ignore
import { VueEllipseProgress } from "vue-ellipse-progress";
import useStore from "@/store";
import DebugResultsView from "@/views/_components/DebugResultsView.vue";

export default defineComponent({
  components: {
    DebugResultsView,
    AppTooltip, VueEllipseProgress
  },
  props: {
    info: { type: Object as PropType<ChakraInfo>, required: true }
  },
  setup(props, context) {
    const store = useStore()

    const {
      biorhythmSuperMinValue,
      biorhythmCompatibleMinValue,
      biorhythmDissonanceMaxExclValue,
      getBiorhythmColorVariant,
      getCssVariableColorHex,
      getBiorhythmColorHex,
      getBiorhythmCompatibleText,
      getBiorhythmChakraResultText
    } = useBiorhythmUtils()

    const progressColor = computed(() => getBiorhythmColorHex(props.info.result))
    const progressEmptyColor = computed(() =>
        getCssVariableColorHex(store.getters.isDarkMode ? 'primary-dark' : 'primary-back'))

    const isSuper = computed(() => props.info.result >= biorhythmSuperMinValue)
    const isCompatible = computed(() => props.info.result >= biorhythmCompatibleMinValue)
    const isDissonance = computed(() => props.info.result < biorhythmDissonanceMaxExclValue)
    const compatibleText = computed(() => getBiorhythmCompatibleText(props.info.result))
    const chakraResultText = computed(() => getBiorhythmChakraResultText(props.info.type, props.info.result))

    const isDebugResultsEnabled = ref<boolean>(false)

    if (process.env.NODE_ENV !== 'production') {
      if (Helpers.isExists(props.info._debugResults)) {
        isDebugResultsEnabled.value = true
      }
    }

    return {
      helpers: Helpers,
      ChakraClass,

      getBiorhythmColorVariant,
      getBiorhythmCompatibleText,
      getBiorhythmChakraResultText,

      progressColor,
      progressEmptyColor,
      isSuper,
      isCompatible,
      isDissonance,
      compatibleText,
      chakraResultText,

      isDebugResultsEnabled
    }
  }
})
