import {PythagoreanSquarePairResult, PythagoreanSquareTraitInfo, PythagoreanSquareResult} from "./models";

const parseDigit = (num: number, digitCount: number) => {
  return num.toLocaleString('en-US', {
    minimumIntegerDigits: digitCount,
    useGrouping: false
  })
}
const getSumOfDigits = (num: number, digitCount: number) => {
  return parseDigit(num, digitCount)
    .split('')
    .reduce((acc, curr) => {
      return acc + Number(curr);
    }, 0);
}
const getDigitAtPosition = (num: number, digitCount: number, position: number) => {
  const result = Number(parseDigit(num, digitCount)[position])
  
  if (typeof result !== 'number' || !isFinite(result)) throw new Error("[getDigitAtPosition] error in num: " + num)
  
  return result
}
const getDigitsCount = (rows: number[][], digits: number[]) => {
  let count = 0
  
  for (let i = 0; i < rows.length; i++) {
    for (let j = 0; j < rows[i].length; j++) {
      for (let k = 0; k < digits.length; k++) {
        if (rows[i][j] === digits[k]) count++;
      }
    }
  }
  
  return count
}

const calculatePythagoreanSquare = (utcDate: Date): PythagoreanSquareResult => {
  const day = utcDate.getUTCDate()
  const month = utcDate.getUTCMonth() + 1
  const year = utcDate.getUTCFullYear()
  
  const dayFirstDigit = day > 9 ? getDigitAtPosition(day, 2, 0) : day
  
  const number1 = getSumOfDigits(day, 2) + getSumOfDigits(month, 2) + getSumOfDigits(year, 4)
  const number2 = getSumOfDigits(number1, 2)
  const number3 = number1 - 2 * dayFirstDigit
  const number4 = getSumOfDigits(number3, 2)
  
  const rows: number[][] = [
    `${parseDigit(day, 2)}${parseDigit(month, 2)}${parseDigit(year, 2)}`.split('').map(x => Number(x)),
    `${parseDigit(number1, 2)}${parseDigit(number2, 2)}${parseDigit(number3, 2)}${parseDigit(number4, 2)}`.split('').map(x => Number(x))
  ];

  return {
    character: getCharacterTrait(rows),
    energy: getEnergyTrait(rows),
    interests: getInterestsTrait(rows),
    health: getHealthTrait(rows),
    logic: getLogicTrait(rows),
    labor: getLaborTrait(rows),
    luck: getLuckTrait(rows),
    duty: getDutyTrait(rows),
    memory: getMemoryTrait(rows),
  
    selfEsteem: getSelfEsteemTrait(rows),
    work: getWorkTrait(rows),
    talent: getTalentTrait(rows),
    purpose: getPurposeTrait(rows),
    family: getFamilyTrait(rows),
    habits: getHabitsTrait(rows),
    spirit: getSpiritTrait(rows),
    temperament: getTemperamentTrait(rows),
  }
}

export const calculatePythagoreanSquarePair = (maleUtcDate: Date, femaleUtcDate: Date,
                                               isDebugDataEnabled = false): PythagoreanSquarePairResult => {
  const male = calculatePythagoreanSquare(maleUtcDate)
  const female = calculatePythagoreanSquare(femaleUtcDate)
  
  return {
    male: male,
    female: female,
  
    characterDigitCountDifference: Math.abs(male.character.digitCount - female.character.digitCount),
    energyDigitCountDifference: Math.abs(male.energy.digitCount - female.energy.digitCount),
    interestsDigitCountDifference: Math.abs(male.interests.digitCount - female.interests.digitCount),
    healthDigitCountDifference: Math.abs(male.health.digitCount - female.health.digitCount),
    logicDigitCountDifference: Math.abs(male.logic.digitCount - female.logic.digitCount),
    laborDigitCountDifference: Math.abs(male.labor.digitCount - female.labor.digitCount),
    luckDigitCountDifference: Math.abs(male.luck.digitCount - female.luck.digitCount),
    dutyDigitCountDifference: Math.abs(male.duty.digitCount - female.duty.digitCount),
    memoryDigitCountDifference: Math.abs(male.memory.digitCount - female.memory.digitCount),
  
    selfEsteemDigitCountDifference: Math.abs(male.selfEsteem.digitCount - female.selfEsteem.digitCount),
    workDigitCountDifference: Math.abs(male.work.digitCount - female.work.digitCount),
    talentDigitCountDifference: Math.abs(male.talent.digitCount - female.talent.digitCount),
    purposeDigitCountDifference: Math.abs(male.purpose.digitCount - female.purpose.digitCount),
    familyDigitCountDifference: Math.abs(male.family.digitCount - female.family.digitCount),
    habitsDigitCountDifference: Math.abs(male.habits.digitCount - female.habits.digitCount),
    spiritDigitCountDifference: Math.abs(male.spirit.digitCount - female.spirit.digitCount),
    temperamentDigitCountDifference: Math.abs(male.temperament.digitCount - female.temperament.digitCount),
  }
}

//#region Traits

export const CharacterTraitGoldenMeanCount = 3

const getCharacterTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [1]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    case 0:
      title = "Безвольный"
      description = "«Безвольный» — жить совсем без внутреннего стержня непросто. А если такой характер у мужчины, " +
        "то вдвойне. Однако, если в этой ячейке отсутствуют цифры, то это не значит, что человеку не пробиться в " +
        "этом мире. А значит, что другие ячейки усилены. На них и стоит обратить внимание."
      break
    case 1:
      title = "Эгоист"
      description = "«Эгоист» — непростой характер. Стремление принимать решения и брать ответственность есть. " +
        "Но где-то глубоко. И только ради себя любимого. Напрямую влиять на кого-то и указывать удается плохо. " +
        "Отчасти из-за этого обидчив. Вариант исполнять чью-то волю или закон угнетает. Лучщий путь: развивать " +
        "навыки дипломатии и умения обходить конфликты. Еще любит спор ради спора."
      break
    case 2:
      title = "Мягкий"
      description = "«Мягкий» — нормальная воля и уравновешенный характер. Может быть лидером без диктата и " +
        "агрессии, а может быть уступчивым и терпеливым подчиненным, проявляющим инициативу. К жестким конфликтам не склонен."
      break
    case CharacterTraitGoldenMeanCount:
      title = "Золотая середина"
      description = "«Золотая середина» — характер так назван, потому что умеет ладить со всеми остальными характерами. " +
        "Над более слабым будет корректно доминировать, а под более сильный умело подстраиваться и даже иногда " +
        "спорить на равных. Однако, часто проявляет себя очень конфликтным, бросающим вызов другим лидерам и обществу."
      break
    case 4:
      title = "Лидер"
      description = "«Лидер» — спокойный и всегда уверенный в себе. Это две главные черты. Негромко, но весомо " +
        "заявляет свое мнение, заставляя прислушаться или даже притихнуть остальных. Жесткость проявляет редко, " +
        "но метко. Она и так в нем чувствуется. Большинство самых богатых и влиятельных людей из политики, бизнеса, " +
        "военной сферы и спорта имеют характеры 1111 и 11111."
      break
    case 5:
      title = "Диктатор"
      description = "«Диктатор» — неспокойный, часто агрессивный и самоуверенный лидер. Имеет жизненную необходимость " +
        "во власти, в организации и руководстве другими. Поэтому не боится конфликтов. А иногда даже провоцирует их, " +
        "зная, что победит. Не приемлит чужих точек зрения. Большинство самых богатых и влиятельных людей из политики, " +
        "бизнеса, военной сферы и спорта имеют характеры 1111 и 11111."
      break
    case 6:
      title = "Деспот"
      description = "«Деспот» — как лидер слишком агрессивен и навязчив, а такое лидерство редко не может быть " +
        "позитивным для других. Иногда это уже на грани с животным доминированием. Больше всего «деспот» опасается " +
        "характеров 1111 и 11111, зная, что не сможет победить в конфликте с ними. Из-за этого вынужден часто " +
        "сдерживать стремление к власти, вымещая комплексы на близких. Крайне непростой характер."
      break
    default:
      title = "Законченный диктатор"
      description = "«Законченный диктатор» — стремление к власти и доминированию над всеми окружающими доходит до " +
        "самодурства. К руководству кем-либо или чем-либо подпускать не рекомендуется, т.к. навязывает всем свое " +
        "мнение и не слушает других мнений. Подчиненные рискуют оказаться подопытными. С другой же стороны, может " +
        "быть успешен по причине этих качеств. Но будут ли рады этому успеху его близкие?"
      break
  }
  
  return {
    isMain: true,
    digits: digits,
    digitCount: digitCount,
  
    title: title,
    description: description,
  }
}

const getEnergyTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [2]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    case 0:
      title = "Нет энергии"
      description = "«Нет энергии» — быстрая утомляемость от перенапряжения или рутинной работы. Частые депрессии. " +
        "Выраженное желание черпать энергию от эмоций(особенно негативных) других людей — по сути вампиризм. Будет " +
        "сложно выбрать себе работу по силам. Для жизни необходимы здоровый сон, упражнения, йога."
      break
    case 1:
      title = "Дефицит энергии"
      description = "«Дефицит энергии» — батарейки хватает только на то, чтобы успеть самое необходимое. Большие " +
        "коллективы, аудитории и шумные тусовки изматывают. Многодневные походы и монотонная работа - туда же. " +
        "А вот умеренный спорт, фитнес, йога, ну, или, как минимум, прогулки и нормальный сон жизненно необходимы."
      break
    case 2:
      title = "Нормальная энергия"
      description = "«Нормальная энергия» — любые обычные дела или кратковременные волевые действия будут по плечу. " +
        "С такой энергией можно заниматься общественной работой, выступать перед аудиторией, быть музыкантом или " +
        "спортсменом. Но без фанатизма, иначе есть риск перегореть. Начиная с 22, человек способен даже немного " +
        "делиться энергией с другими."
      break
    case 3:
      title = "Импульсивная энергия"
      description = "«Импульсивная энергия» — в обычном состоянии ничем не отличается от 22, но в экстренные моменты " +
        "человек способен на мощный рывок или сверх-усилие. Часто такой человек даже одним своим присутствием может " +
        "улучшить состояние больного или вселить оптимизм в безнадежного отчаявшегося партнера. Такие люди бывают " +
        "выдающимися общественными деятелями, музыкантами, бизнес-тренерами и вдохновителями для других. Опасность же " +
        "состоит в том, что к носителю 222, как к магниту, тянутся энергетические вампиры, особенно противоположного пола."
      break
    case 4:
      title = "Сильная энергия"
      description = "«Сильная энергия» — энергия в избытке. Хватает и для себя, и для других. Поэтому является донором " +
        "для большинства людей с низкой энергией. Человек может быть и политиком, и артистом, и музыкантом, и " +
        "спортсменом, и даже экстрасенсом с потенциалом целителя. Окружающие ощущают в нем то, что называется " +
        "«харизмой». В потенциале такой человек может влиять на волю других. Неудивительно, что в больших компаниях " +
        "и тусовках ему удается притягивать противоположный пол, особенно с энергией от 0 до 22."
      break
    case 5:
      title = "Очень сильная энергия"
      description = "«Очень сильная энергия» — экстрасенс, целитель и мощный энергичный лидер от природы. Энергия бьет " +
        "через край, создавая вокруг ауру «харизмы» и притягивая, как магнит, окружающих. Однако, знайте, что это " +
        "притягивает далеко не только положительных персонажей. Сильный запас энергии создает иллюзию ее бесконечности, " +
        "поэтому следует учиться тратить ее с умом и во благо. Миссия этого знака — отдавать энергию, помогать людям " +
        "и делать мир лучше."
      break
    default:
      title = "Энергия зашкаливает"
      description = "«Энергия зашкаливает» — природные способности к влиянию на других, экстрасенсорике и целительству " +
        "легко переходят в мощный поток негативной энергии в случае неумения управлять своим даром. Окружающие могут " +
        "даже побаиваться такого персонажа, один лишь взгляд которого вызывает молнию, а недоброе слово накладывает " +
        "проклятие. Хотя, вместе с тем, в спокойной обстановке такой человек по-прежнему харизматичен и привлекателен. " +
        "Но редко кто сможет с ним сладить."
      break
  }
  
  return {
    isMain: true,
    digits: digits,
    digitCount: digitCount,
    
    title: title,
    description: description,
  }
}

const getInterestsTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [3]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    case 0:
      title = "Нулевой потенциал"
      description = "«Нулевой потенциал» — склонности к познанию точных наук, сложных концепций и изобретательству " +
        "нет. Нет и стабильного интереса к чему-либо подобному. Не беда - значит, ваш путь другой."
      break
    case 1:
      title = "Небольшой интерес"
      description = "«Небольшой интерес» — уровень, которого хватает для поверхностного освоения точных наук, техники, " +
        "алгоритмов и систематизации знаний. Есть некоторые задатки к изобретательству, творчеству и проявлению оригинальности."
      break
    case 2:
      title = "Базовые способности"
      description = "«Базовые способности» — с таким потенциалом можно при желании достаточно глубоко овладеть " +
        "точными науками, техникой, алгоритмами или системой знаний. Либо пойти по пути искусства и придумывать " +
        "романы, музыку, фильмы. И даже просто благодаря фантазии такой человек способен иногда генерировать " +
        "оригинальные идеи. Но для самореализации по этому пути потребуются большой труд и развитие навыка."
      break
    case 3:
      title = "Импульсивный ученый и творец"
      description = "«Импульсивный ученый и творец» — имеет серьезные перспективы овладеть любой точной наукой, " +
        "техникой, алгоритмом или системой знаний, заявить о себе в любых творческих сферах. Вдобавок к этому еще " +
        "фантазер и экспериментатор — это то, что отличает от 33 и 3. Из опасностей: слишком широкий кругозор, " +
        "часто больная фантазия и распыление интересов, невозможность сконцентрироваться на чем-то, требующем " +
        "большого погружения. Способности могут проявляться спонтанно и не всегда в тот момент, когда это требуется."
      break
    case 4:
      title = "Ученый и изобретатель"
      description = "«Ученый и изобретатель» — редчайший знак в наш век. Потенциал настоящего ученого, изобретателя " +
        "и творца. И это не просто тот, кто изучит теорию глубже всех. Это то, кто способен погрузиться в предметную " +
        "область так, что вернется из нее с настоящим открытием. Для окружающих это человек не от мира сего и часто " +
        "бывает не принят. Все это из-за способности мыслить новыми парадигмами. Жаль, что не всегда в его власти " +
        "дать ход своим идеям, которые могли бы перевернуть мир, но чаще остаются пылится в столе. Для такого " +
        "человека потребуется много работы над собой, а родителям такого ребенка важно давать ему простор для " +
        "творчества, фантазии и увлечений. Ведь сфера проявления 3333 может быть совершенно неожиданной."
      break
    case 5:
      title = "Экстра способности познания и творчества"
      description = "«Экстра способности познания и творчества» — редчайший знак в наш век. Внутри этого человека " +
        "находится великий ученый, изобретатель и творец. В потенциале его идеи способны перевернуть мир. Но " +
        "вырастет ли из него новый Эйнштейн? Ведь общество будет всячески противиться ему и насмехаться над ним. " +
        "Потребуется немало душевных сил, чтобы быть выше этого. Поэтому такие гении чаще становятся отшельниками."
      break
    default:
      title = "Безумный ученый"
      description = "«Безумный ученый» — сильные качества ученого, изобретателя и творца, но еще сильнее нежелание " +
        "развиваться из-за небывалой самоуверенности в себе, своих способностях и превосходстве. Со временем у такого " +
        "человека может развиться неуверенность в своих идеях или он может зациклиться на чем-то совершенно " +
        "бесперспективном, считая, что другие просто не понимают его гениальности. Главным в жизни для него является " +
        "найти баланс между своим эгоистичным гением и стремлении быть полезным для общества."
      break
  }
  
  return {
    isMain: true,
    digits: digits,
    digitCount: digitCount,
    
    title: title,
    description: description,
  }
}

const getHealthTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [4]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    case 0:
      title = "Низкий запас здоровья"
      description = "«Низкий запас здоровья» — здоровье слабое и подвергать его лишнему напряжению не стоит. Фитнес, " +
        "йога, правильное питание, профилактика — лучшие друзья. Делать ставку на внешние данные и красоту такому " +
        "человеку не стоит. Лучше обратить внимание на другие сильные качества и сфокусироваться на них в поисках себя."
      break
    case 1:
      title = "Начальный запас здоровья"
      description = "«Начальный запас здоровья». Обычно одна четверка уходит либо в здоровье, либо в красоту. " +
        "Поэтому если ваше отражение в зеркале кажется неплохим, то обратите внимание на здоровье, на возможные " +
        "скрытые недуги — их важно обнаружить вовремя. Если же здоровье вас не слишком беспокоит, то скорее всего " +
        "не стоит рассчитывать на карьеру модели."
      break
    case 2:
      title = "Нормальные здоровье и красота"
      description = "«Нормальные здоровье и красота». Качество в норме, но все же на практике одно из двух развито " +
        "сильнее - или здоровье, или красота. В целом здоровье 44 позволяет легче переносить болезни и быстрее " +
        "восстанавливаться. А внешность пара четверок делает более гармоничной и привлекательной в глазах " +
        "противоположного пола, хотя сам носитель этого порой не замечает. Есть задатки для занятий спортом или " +
        "работой, связанной с внешними данными. Физические упражнения, фитнес и здоровый образ жизни принесут " +
        "очень хороший результат для таких людей. Но совсем отказываться от поддержания здоровья, полагаясь лишь " +
        "на врожденный потенциал, не стоит. Старость и вредные привычки губят всех. Но вас чуть медленнее."
      break
    case 3:
      title = "Здоровье и красота выше среднего"
      description = "«Здоровье и красота выше среднего». Три четверки, как правило, делятся между здоровьем и " +
        "красотой. Но, бывает, и уходят во что-то одно. С виду такой человек гармонично сложен и красив. Может " +
        "использовать свои задатки и стать профессиональным спортсменом, актером или фотомоделью. В старости он " +
        "способен сохранять ясный ум и хорошую активность. Но не стоит быть слишком самоуверенным. Носители 444 " +
        "склонны переоценивать физическую сторону своей личности."
      break
    case 4:
      title = "Развитые здоровье и красота"
      description = "«Развитые здоровье и красота». Здоровье и тело профессионального спортсмена, актера или модели " +
        "от природы. Таким же чаще всего, если не истратит свои природные данные, останется до глубокой старости. " +
        "Обычно четверки уходят либо в здоровье, либо во внешность. Поэтому супер здоровья и сверх красоты в одном " +
        "человеке практически не бывает. Но что-то одно из этого носителю 4444 точно поможет в жизни и на это стоит " +
        "делать ставку."
      break
    case 5:
      title = "Выдающиеся здоровье и красота"
      description = "«Выдающиеся здоровье и красота». Такой человек мог бы стать великим спортсменом или " +
        "секс-символом века. Ведь стареют такие люди значительно медленнее, благодаря природным данным. И эти " +
        "данные должны быть не просто бонусом или внешним украшением, а должны использоваться для достижения целей, " +
        "для продвижения в обществе и самореализации."
      break
    default:
      title = "Здоровье быка и внешность модели"
      description = "«Здоровье быка и внешность модели» — редко кому так везет. Здоровье и тело профессионального " +
        "спортсмена или модели — это, конечно, неплохо. Плохо то, что такой человек везде старается использовать " +
        "богатырскую силу или внешние данные, как главный аргумент. И это не вызывает уважения в обществе."
      break
  }
  
  return {
    isMain: true,
    digits: digits,
    digitCount: digitCount,
    
    title: title,
    description: description,
  }
}

const getLogicTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [5]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    case 0:
      title = "Логика — не ваш конек"
      description = "«Логика — не ваш конек». Это черта мечтателей, витающих в облаках. Плохо воспринимают длинные " +
        "цепочки фактов, рассуждений. Могут быть легко запутаны, поэтому постоянно бьются о грабли своих ошибок. " +
        "Более эмоциональны, нежели рациональны."
      break
    case 1:
      title = "Задатки логики"
      description = "«Задатки логики» — логика в человеке присутствует. Если он с первого раза не осилил что-то, " +
        "то с калькулятором и бумагой обязательно разберется. Квантовая физика ему вряд ли покориться, а вот " +
        "для элементарных жизненных задач должно хватить."
      break
    case 2:
      title = "Нормальная логика"
      description = "«Нормальная логика» — логика позволяет быть неплохим аналитиком или программистом. А благодаря " +
        "еще и задаткам интуции человек мог бы, например, раскрывать сложные преступления в качестве следователя. " +
        "Такой человек способен построить прибыльный бизнес буквально в любой сфере: ведь он руководствуется " +
        "показателями, а не второстепенными вещами."
      break
    case 3:
      title = "Иррациональная логика"
      description = "«Иррациональная логика» — логика развита, но случаются еще и вспышки всеохватывающей " +
        "сверх-логики или интуиции. Особенно в критических ситуациях и условиях очень сжатых сроков для принятия " +
        "решения. В такие моменты человек как бы ускоренно видит все шаги и этапы наперед. Научиться воспринимать " +
        "свою интуицию в такие мгновения — вот ключ к успеху такого человека. А в обычные же моменты это человек, " +
        "способный хорошо просчитывать свои действия и выгоды от них."
      break
    case 4:
      title = "Сильная логика и интуиция"
      description = "«Сильная логика и интуиция» — кажется, что такому человеку ответы на все вопросы даются " +
        "буквально сверху. На самом же деле это результат особой способности к восприятию большого количества " +
        "информации, ее структурирования и обработки. От этого прогнозы по исходу тех или иных событий, данные " +
        "носителем 5555, часто оказываются близки к реальности. Одни из лучших сфер применения таланта этого " +
        "человека: бизнес, аналитика, финансы, программирование."
      break
    case 5:
      title = "Блестящая логика и дар предсказания"
      description = "«Блестящая логика и дар предсказания» — логика и интуитивный канал на максимуме. Что в некоторой " +
        "степени подавляет эмоциональность. С этого рубежа начинает проявлять себя дар предвидения. Иногда кажется, " +
        "что такой человек как бы глядит вне времени и пространства. На самом же деле он просто способен прокрутить " +
        "в голове такое множество фактов, событий и цифр, что не каждый в состоянии даже представить. Многие считают " +
        "его «человеком-компьютером»."
      break
    default:
      title = "Роковой дар предсказания"
      description = "«Роковой дар предсказания» — каждый прогноз и каждый анализ становятся фатальными. Но здесь " +
        "нет никакой магии: этот человек дает решения самых сложных задач не потому, что уже знает их, как кому-то " +
        "могло бы показаться. Он просто способен воспринимать и оперировать таким количеством информации, которые " +
        "недоступны для мозга обычных людей. Но здесь нет никакой магии: этот человек дает решения самых сложных " +
        "задач не потому, что уже знает их, как кому-то могло бы показаться. Он просто способен воспринимать и " +
        "оперировать таким количеством информации, которые недоступны для мозга обычных людей."
      break
  }
  
  return {
    isMain: true,
    digits: digits,
    digitCount: digitCount,
    
    title: title,
    description: description,
  }
}

const getLaborTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [6]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    case 0:
      title = "Мастер на все руки — это не про вас"
      description = "«Мастер на все руки — это не про вас». Лучше не браться за деятельность, которая выглядит " +
        "сложной, кропотливой и требующей аккуратности или досконального соблюдения техники. Впрочем, обычно такие " +
        "люди и сами не очень жалуют подобные сферы. Ручной труд не их конек."
      break
    case 1:
      title = "Задатки трудолюбия и мастерства"
      description = "«Задатки трудолюбия и мастерства» — по настроению человек может вполне неплохо справиться с " +
        "любой работой, требущей приложения трудолюбия, аккуратности и техники процесса. Конечно, ювелиром он " +
        "сразу не станет, но будь то строительство дома, ремонт или что-то не требующее сверх искусных навыков, " +
        "то, скорее всего, получится. А в процессе он сможет еще и отточить умения."
      break
    case 2:
      title = "Золотые руки"
      description = "«Золотые руки» — при постоянном совершенствовании навыков человек может достигнуть мастерства " +
        "в любом деле, требующем трудолюбия, аккуратности и соблюдения техники процесса. Но придется потрудиться, " +
        "чтобы стать настоящим мастером своего дела."
      break
    case 3:
      title = "Дело мастера боится"
      description = "«Дело мастера боится» — такой человек может искусно делать свою работу, завораживая при этом " +
        "окружающих самим процессом. Неизбежно достигнет успеха, если потратил на оттачивания навыков достаточно " +
        "времени. Для него это дело техники. Еще говорят, что людям с таким знаком следует опасаться других людей " +
        "с большим количеством шестерок в квадрате: мастер более высокого уровня всегда побеждает. Причем, это " +
        "может касаться любой сферы: от укладывания плитки, фотографирования и доходя до ювелирного дела или бокса."
      break
    case 4:
      title = "Герой труда и мастер своего дела"
      description = "«Герой труда и мастер своего дела» — причем рождаются такими буквально сразу. Для такого " +
        "человека главное — найти сферу, в которой было бы интересно прилагать усилия и совершенствоваться. В " +
        "результате развития все, за что бы он ни брался, получается буквально шедевром. Окружающие же восхищаются, " +
        "уважают и завидуют. Один нюанс: верх мастерства чаще всего способны понять далеко не все, а доступно " +
        "подлинное восхищение лишь ценителям."
      break
    case 5:
      title = "Рука бога"
      description = "«Рука бога» — в количестве двух штук. Все, за что бы не взялся, получается образцом. Все " +
        "окружающие восхищаются, уважают и завидуют искусному обращению с фотоаппаратом, спицами, кистью, " +
        "гаечным ключом или даже футбольным мячом. Главное, чтобы дело, раскрывающее талант носителя, не оставалось " +
        "простым увлечением, а нашло профессиональное применение. И тогда успех будет неминуем."
      break
    default:
      title = "Мастер-ломастер"
      description = "«Мастер-ломастер» — ему кажется все настолько знакомо и избито, что даже не хочется ничего " +
        "делать. Есть мнение, что слишком много работал руками в прошлой жизни или был столь великим искусным " +
        "мастером, что теперь ему хотелось бы лишь пожинать плоды. Часто возводит технику исполнения в абсолют, " +
        "теряя по дороге смысл и значение продукта своего кропотливого труда."
      break
  }
  
  return {
    isMain: true,
    digits: digits,
    digitCount: digitCount,
    
    title: title,
    description: description,
  }
}

const getLuckTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [7]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    case 0:
      title = "Звезд с неба не хватает"
      description = "«Звезд с неба не хватает» — все нужно достигать самому. При этом делать много попыток, не " +
        "ожидая внезапного успеха. Но согласитесь: достичь честного успеха своим трудом и случайного успеха по " +
        "воле фортуны - разные вещи. Поэтому смотрите на другие свои сильные стороны и реализуйте себя, раскрывая их."
      break
    case 1:
      title = "По жизни с талисманом"
      description = "«По жизни с талисманом» — конечно, одна семерка не гарантирует джекпот в казино с первой " +
        "ставки, но сбережет от мелких неприятностей и иной раз поможет успеть вскочить на ступеньку уходящего поезда."
      break
    case 2:
      title = "Везунчик"
      description = "«Везунчик» — если вы не тот самый «лежачий камень», то судьба поможет вам быть в нужное время " +
        "в нужном месте. А иногда она буквально отводит стороной от бед и неправильных решений. С такими людьми " +
        "бывает, что после серии удач они становятся слишком самоуверенными, полагая, что и в очередной раз им " +
        "повезет, а усилий для этого прикладывать не придется. Как бы не так: именно в этот момент фортуна любит " +
        "щелкнуть зазнавшихся везунчиков по носу."
      break
    case 3:
      title = "Три семерки"
      description = "«Три семерки» — знак крупного успеха и полноправного владения своей судьбой. Но к крупному " +
        "успеху удастся прийти, преодолев в качестве проверки не менее серьезные испытания. Есть одна проблема у " +
        "человека с таким знаком: у него сбывается ровно то, чего он сам желает. Как только мысли становятся " +
        "негативными и вера в себя пропадает — результат не заставляет себя ждать."
      break
    case 7:
      title = "Мессия"
      description = "«Мессия» — знак удачи переходит в покровительство свыше. Но оно дано не ради выигрыша в " +
        "казино. Такой человек может увести за собой других из любого катаклизма и перед ним будут расступаться " +
        "даже моря. Быть спасителем — его цель в жизни. Опасность же состоит в том, что много людей с таким " +
        "знаком нелепо погибают, самоотверженно действуя в критических ситуациях."
      break
    case 6:
      title = "Творец судьбы"
      description = "«Творец судьбы» — своей и судьбы тех, рядом с кем он оказался. Такой человек живет уже не для " +
        "себя, а во имя спасения мира. Его задачей является не размениваться по мелочам, а действовать масштабно, " +
        "ставя перед собой большие цели. Если они будут благими для общества, то ему непременно повезет в них."
      break
    default:
      title = "Злой рок"
      description = "«Злой рок» — перебор семерок приводит к злому року, сопутствующему по жизни. Один раз " +
        "оступившись, такой человек начинает думать о том, что ему не везет и нагнетает вокруг себя негативную " +
        "энергетику. Разорвать порочный круг будет не так просто, но именно это выведет его из череды неудач."
      break
  }
  
  return {
    isMain: true,
    digits: digits,
    digitCount: digitCount,
    
    title: title,
    description: description,
  }
}

const getDutyTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [8]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    case 0:
      title = "Никому ничего не должен"
      description = "«Никому ничего не должен» — долг перед обществом или кем бы то ни было такому человеку обычно " +
        "не знаком. Его нельзя назвать полным эгоистом, но все его цели направлены в основном на себя. В мечтах он " +
        "стремится к «свободе» и «независимости», которые состоят для него в отсутствии какой бы то ни было " +
        "ответственности перед кем-либо или чем-либо."
      break
    case 1:
      title = "Чувство долга в меру"
      description = "«Чувство долга в меру» — долга перед близкими и друзьями такие люди обычно не избегают. Но не " +
        "более того. Их инициатива что-то дать окружающим и обществу низка. Но хотя бы не забывают тех, кто " +
        "рядом. Самопожертвование — это тоже не про них."
      break
    case 2:
      title = "Несут добро в массы"
      description = "«Несут добро в массы» — обычно люди с хорошо развитой социальной ориентацией и высокими " +
        "коммуникативными навыками. Если это раскрыто с детства, то они положительно реализуют себя в общественной " +
        "деятельности, им в радость отдавать себя во вне, но без фанатизма. Если социальная реализация не " +
        "сложилась, то часто может направлять эту энергию на брошенных собак и кошек, бездомных, проявляют " +
        "заботу о больных и одиноких."
      break
    case 3:
      title = "Правдоруб"
      description = "«Правдоруб» — для них стремление донести себя и свои идеи (естественно, «правильные» и " +
        "«добрые» с их точки зрения) в общество часто ставится выше собственных или семейных интересов. И, " +
        "естественно, все это делается, якобы, для общественного же блага. В общем, социальная ориентация у людей " +
        "такого типа может иногда зашкаливать. А иногда, как бы ни странно это выглядело со стороны, у таких людей " +
        "может наблюдаться период замкнутости. До того момента, пока они вновь остро не заходят бурной социальной жизни."
      break
    case 4:
    case 5:
      title = "Парапсихолог"
      description = "«Парапсихолог» — редчайший знак нашего времени, указывающий на наличие парапсихологических " +
        "способностей у личности, позволяющих оказывать воздействие на массы. Может быть использован как во благо, " +
        "так и во вред. Раскрытие этих особых способностей и направление их в положительное русло следует " +
        "осуществлять через поиск ментора и работу с ним."
      break
    default:
      title = "Изгнанник"
      description = "«Изгнанник» — карма такого знака видеть всю ложь и зло этого мира насквозь. Но как бы он с ним " +
        "ни боролся, это всегда будет безуспешно - ведь окружающий мир состоит из равновесия черного и белого. " +
        "Однако, наш персонаж зациклен на противоборстве с черным."
      break
  }
  
  return {
    isMain: true,
    digits: digits,
    digitCount: digitCount,
    
    title: title,
    description: description,
  }
}

const getMemoryTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [9]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    case 0:
      title = "Короткая память"
      description = "«Короткая память» — воспринимать, обрабатывать и запоминать большие объемы информации " +
        "довольно тяжело. Все это создает проблемы в учебе и работе с массивами информации. Часто такие люди " +
        "могут заблудиться в незнакомом месте или забыть, куда шли. Зато отсутствие стереотипов в памяти дает " +
        "хорошие возможности для творчества."
      break
    case 1:
      title = "Базовый интеллект"
      description = "«Базовый интеллект» — информация запоминается по принципу «повторенье — мать ученья». Объем " +
        "памяти для запоминания не слишком велик. Поэтому не стоит перегружать себя лишними знаниями — все равно " +
        "забудется. Но на практике можете вполне нормально оперировать полученными знаниями, с которыми " +
        "работаете постоянно."
      break
    case 2:
    case 3:
      title = "Светлая голова"
      description = "«Светлая голова» — любая учеба или работа с информацией дается очень легко. И это часто " +
        "вызывает скуку и нежелание. Эрудитом и интеллектуалом такому человеку стать просто. Но на самом деле следует " +
        "искать действительно интересный и глубокий предмет применения своего ума. И чем сложнее он будет, тем " +
        "большим будет интерес и, соответственно, результат."
      break
    case 4:
      title = "Супер-компьютер"
      description = "«Супер-компьютер» — человеку открываются истина и природа знания. Школьная программа, высшая " +
        "математика, китайский язык, программирование — для него как семечки. В голове может держать и обрабатывать " +
        "множество процессов. Но почему-то сильный интеллект отличает грубость и презрение в общении с окружающими. " +
        "Свое мнение считает истинно верным."
      break
    case 5:
      title = "Сверх-разум"
      description = "«Сверх-разум» — благодаря сверх-интеллекту человек может оперировать колоссальными объемами " +
        "информации. А главное, что отличает пик интеллекта 99999 от остальных - возможность встраивания в " +
        "информационные каналы, где он буквально видит и анализирует все возможные варианты. В общении не менее " +
        "грубоваты, чем 9999. Редчайший знак."
      break
    default:
      title = "Перегруженный компьютер"
      description = "Перегруженный компьютер» — качество пророка, с рождения знающего буквально все. Получает " +
        "информацию о вариантах развития событий в будущем как осознанно, так и неосознанно. Не умея пользоваться " +
        "способностями, пугается собственных видений."
      break
  }
  
  return {
    isMain: true,
    digits: digits,
    digitCount: digitCount,
    
    title: title,
    description: description,
  }
}

// additional
const getSelfEsteemTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [1, 2, 3]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    case 0:
      title = "Низкая"
      description = "«Низкая» — не предъявляет высоких требований к себе и своей жизни. \"И так сойдет\" - говорит " +
        "он себе. Естественно, не любит выскочек и тех, кто слишком много о себе думает."
      break
    case 1:
      title = "В норме"
      description = "«В норме» — самооценка не низкая, но и не высокая. Стремится быть как все и нисколько не " +
        "стесняется этого. Мог быть и сам достичь больше, но ориентируется на средний уровень. Соответственно, " +
        "не очень жалует людей с самооценкой выше."
      break
    case 2:
      title = "Нестабильная"
      description = "«Нестабильная» — где-то человек проявляет вполне обычную самооценку. Но в некоторых моментах " +
        "он явно завышает свои возможности, ставя абсолютно нереальные цели. Затем следует два пути: либо цель " +
        "резко отклоняется, либо, после того, как она, естественно, не была достигнута, ищутся виноватые."
      break
    case 3:
      title = "Высокая"
      description = "«Высокая» — не хочет быть как все, чувствует себя выше других. Стремится достичь большего, " +
        "зная, что он этого достоин, и прилагает к этому усилия. Отметим, что высокая и завышенная самооценка — " +
        "самый реальный стимул чего-то добиться в жизни."
      break
    case 4:
      title = "Высшая"
      description = "«Высшая» — эта самооценка позволяет реализовывать самые смелые амбиции. Такой человек даже " +
        "если где-то и переоценивает себя, то старается соответствовать взятой планке требований. Отметим, что " +
        "высокая и завышенная самооценка — самый реальный стимул чего-то добиться в жизни."
      break
    default:
      title = "Завышенная"
      description = "«Завышенная» — чаще всего человек неадекватно высоко оценивает себя. Кроме того характерны " +
        "позиции в духе «я достоин большего» и т.п. Что ж, в таком случае надо либо работать над достижением того, " +
        "чего достоин, либо снизить пыл. Отметим, что высокая и завышенная самооценка — самый реальный стимул " +
        "чего-то добиться в жизни. Но большинство из таких людей ждет кризис в определенный момент, когда они " +
        "оглянутся с высоты прожитых лет и поймут, что не стали тем, кем хотели бы. И здесь они либо уходят в " +
        "глубокую депрессию, либо с новыми силами делают рывок вперед."
      break
  }
  
  return {
    isMain: false,
    digits: digits,
    digitCount: digitCount,
    
    title: title,
    description: description,
  }
}

const getWorkTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [4, 5, 6]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    case 0:
      title = "Иждевенец"
      description = "«Иждевенец» — не способен позаботиться о собственном материальном обеспечении. Окружение и " +
        "близкие видят в этом инфантильность и несамостоятельность. Но и с годами ничего в нем не меняется."
      break
    case 1:
      title = "Не самостоятельный"
      description = "«Не самостоятельный» — сделает все, чтобы как можно дольше оставаться под крылом родителей. " +
        "А затем будет искать супруга, способного полностью обеспечивать семью. Точно не знает, как сколотить " +
        "состояние, поэтому может браться сразу многие способы, но бросать, не имея ожидаемого успеха."
      break
    case 2:
      title = "Беспокоящийся"
      description = "«Беспокоящийся» — мысль о необходимости обеспечения себя вводит в беспокойство. С одной " +
        "стороны, при наличии возможности, был бы рад не работать совсем. С другой же — если необходимость стоит, " +
        "то соглашается на работу, лишь бы платили."
      break
    case 3:
      title = "Вахтовый метод"
      description = "«Вахтовый метод» — идеальный способ материального обеспечения по мнению такого человека: " +
        "быстро и эффективно заработал на жизнь в короткое время, чтобы затем расслабиться. Чаще всего ищут " +
        "сдельную оплату труда."
      break
    case 4:
      title = "Добытчик"
      description = "«Добытчик» — высокое стремление обеспечить себя и свою семью. Не боится сложной и ответственной " +
        "работы ради хорошего материального поощрения. Более того: такая работа буквально его сама ищет. В силах " +
        "открыть свое дело и заработать на этом."
      break
    case 5:
      title = "Опора и надежда"
      description = "«Опора и надежда» — в материальном плане на такого человека 100% можно положиться. Ценит " +
        "работу и ценит себя, поэтому ищет то место, где можно хорошо зарабатывать. Обеспечение себя и семьи являются " +
        "для него главными целями в жизни. Прекрасные задатки для занятия прибыльным бизнесом."
      break
    default:
      title = "Зациклен"
      description = "«Зациклен» — ничего не видит перед собой, кроме достижения материального благополучия. Может " +
        "браться сразу за несколько работ, быстро уставая от этого. В периоды усталости вынужден имитировать деятельность."
      break
  }
  
  return {
    isMain: false,
    digits: digits,
    digitCount: digitCount,
    
    title: title,
    description: description,
  }
}

const getTalentTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [7, 8, 9]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    default:
      title = "Талант " + digitCount
      description = "Если в человеке есть талант, т.е. он явно выражен (можно обратить внимание на другие ячейки) и " +
        "человек над ним работает, то эта цифра говорит о потенциале развития этого таланта."
      break
  }
  
  return {
    isMain: false,
    digits: digits,
    digitCount: digitCount,
    
    title: title,
    description: description,
  }
}

const getPurposeTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [1, 4, 7]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    case 0:
      title = "Не целеустремленный"
      description = "«Не целеустремленный» — не умеет ставить перед собой и достигать целей. Даже если поставил, " +
        "то может с легкостью отказаться. Обычно ставновится ведомым в обществе."
      break
    case 1:
      title = "Низкая"
      description = "«Низкая» — поставить перед собой пусть и небольшую, но цель, такой человек может. Но вот " +
        "целенаправленно идти по пути ее достижения — нет. Надеется больше на случай, чем на себя."
      break
    case 2:
      title = "В норме"
      description = "«В норме» — целеустремленность не высокая, но и не низкая. Недостижимых и амбициозных целей " +
        "перед собой не ставит. Считает свои возможности обычно ниже, чем есть на самом деле, и идет путем выполнения " +
        "небольших поэтапных целей. Большие цели его скорее отпугивают."
      break
    case 3:
      title = "Непредсказуем" // Непредсказуемая
      description = "«Непредсказуем» — любит ставить цели, а затем внезапно менять их. Кстати, достигать цели не " +
        "входит в любимое занятие — куда приятней ему мечтать о покровительстве над миром, чем завоевывать его. Иногда " +
        "склонен переоценивать себя, сначала взваливая на свои плечи что-то неподъемное, а затем бросая на полпути."
      break
    case 4:
      title = "Уверенный"
      description = "«Уверенный» — ставит высокие амбициозные цели, оценивает ресурсы для их достижения и, как " +
        "правило, выполняет задуманное. Отметим, что высокая целеустремленность — важный фактор успеха в жизни. " +
        "На самом деле такой человек имеет потенциал даже больше, но слишком заоблачные цели его отпугивают. Хотя " +
        "ради вызова в жизни он мог бы попробовать их достичь."
      break
    case 5:
      title = "Упертый"
      description = "«Упертый» — идет к цели, чего бы это ему ни стоило. Может даже потерять больше, чем достигнет, " +
        "но цели добьется. Ищет в партнеры тех, кто тоже стремится получить от жизни больше. Но при этом свысока " +
        "смотрит на тех, чьи цели кажутся ему незначительными. Отметим, что высокая целеустремленность — важный " +
        "фактор успеха в жизни."
      break
    default:
      title = "Идеалист"
      description = "«Идеалист» — порой гонится за откровенно недостижимыми целями, потому что другие не вызывают " +
        "интереса. Тратит много сил, спотыкается, разочаровывается, но потом опять по новой. И верит, что на этот " +
        "раз получится. Часто становится одиночкой, т.к. на его непростом пути мало кто в силах оказать поддержку."
      break
  }
  
  return {
    isMain: false,
    digits: digits,
    digitCount: digitCount,
    
    title: title,
    description: description,
  }
}

const getFamilyTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [2, 5, 8]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    case 0:
      title = "Не семьянин"
      description = "«Не семьянин» — семья для него скорее в тягость. Будет избегать ее создания всеми силами. " +
        "А попав в брак, соблюдает семейные узы лишь формально. Семья не в числе его жизненных приоритетов."
      break
    case 1:
      title = "Не торопливый"
      description = "«Не торопливый» — о семье слышал, но создавать ее спешить не будет. Из тех кто ждет предложения " +
        "и почти никогда не делает сам. Или делает предложение по настоянию со стороны. Или же в том случае, когда " +
        "есть риск потерять партнера."
      break
    case 2:
      title = "Пассивный"
      description = "«Пассивный» — может даже говорить о том, что планирует семью, но реальных шагов к этому " +
        "предпринимает мало. Хотел бы сам получить предложение вместо того, чтобы делать его."
      break
    case 3:
      title = "Спонтанный"
      description = "«Спонтанный» — для такого знака характерно говорить, что в брак никогда не вступит или это не " +
        "для него. Но в один прекрасный день вы получаете от него приглашение на свадьбу, после которой он может " +
        "оказаться вполне себе прекрасным семьянином. А затем также внезапно подать на развод через год."
      break
    case 4:
      title = "Хороший семьянин"
      description = "«Хороший семьянин» — семейные ценности для этого знака не пустой звук. Осознанно планирует " +
        "создание семьи и хорошо проявляет себя потом в качестве верного семьянина. В партнеры желает того, " +
        "кто разделял бы эту цель."
      break
    case 5:
      title = "Семьянин-идеалист"
      description = "«Семьянин-идеалист» — семью и все, что в ней происходит, представляет как некий идеал. Поэтому " +
        "сначала тщательно проверяет партнера на идеальность, а затем, уже в браке, пытается себя, партнера и " +
        "семейные отношения привести к тому самому идеалу. Не всегда партнер готов выдерживать такое."
      break
    default:
      title = "Слишком идеалист"
      description = "«Слишком идеалист» — поиск идеального партнера для создания идеальной семьи может затянуться на " +
        "всю жизнь по причине своей недостижимости. И каждая попытка, которая не приблизит цель, только еще сильные " +
        "расстроит носителя этого знака. В случае брака будет предъявлять завышенные требования к супругу."
      break
  }
  
  return {
    isMain: false,
    digits: digits,
    digitCount: digitCount,
    
    title: title,
    description: description,
  }
}

const getHabitsTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [3, 6, 9]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    case 0:
      title = "Нестабильный"
      description = "«Нестабильный» — патологически не переносит одно и то же вокруг себя, постоянно стремится " +
        "все изменить. И часто он склонен к изменениям без какого-либо практического смысла."
      break
    case 1:
      title = "Сердце просит перемен"
      description = "«Сердце просит перемен» — постоянства в этом человеке мало. Сам он без перемен не смыслит " +
        "себя, но вот окружающие эту тягу не одобряют. Хорошо, хоть в каких-то мелочах он остается стабилен " +
        "и предсказуем для близких."
      break
    case 2:
      title = "Все под контролем"
      description = "«Все под контролем» — очень легок на подъем, но желание перемен контролируемо. Тем не менее, " +
        "такого человека нельзя назвать стабильным."
      break
    case 3:
      title = "Импульсивная стабильность"
      description = "«Импульсивная стабильность» — накопление привычек и привязанностей идет своим чередом. Пока в " +
        "один прекрасный день этот человек не меняет все с ног на голову. Такова его природа."
      break
    case 4:
      title = "Достаточно стабилен"
      description = "«Достаточно стабилен» — наблюдается постоянство в привычках и в окружении. На перемены идет " +
        "тяжело. В этом отношении кажется немного занудным для окружающих. Но зато надежным и предсказуемым."
      break
    case 5:
      title = "Очень стабилен"
      description = "«Очень стабилен» — привычки и окружение практически не меняются. Доверяет тому, к чему привык. " +
        "Может не изменить привычному, даже если новое лучше. Что это: занудство или предсказуемость? Так или иначе, " +
        "кто-то из окружающих это ценит, а кого-это это бесит. Но считается, что такому человеку можно доверять."
      break
    default:
      title = "Перебор привычек"
      description = "«Перебор привычек» — человек становится рабом своей стабильности. Пытается иногда бороться с " +
        "ней, но в основном безуспешно. Одни бесполезные привычки и привязанности сменяются другими. Крайне тяжел " +
        "на подъем для многих близких."
      break
  }
  
  return {
    isMain: false,
    digits: digits,
    digitCount: digitCount,
    
    title: title,
    description: description,
  }
}

const getSpiritTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [1, 5, 9]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    case 0:
      title = "Нет духовности"
      description = "«Нет духовности» — этот человек полностью материален и вопросы духовности его не заботят."
      break
    case 1:
      title = "Низкая духовность"
      description = "«Низкая духовность» — материализм в человеке преобладает гораздо сильнее."
      break
    case 2:
      title = "Обычная"
      description = "«Обычная» — с духовностью все в порядке. Для себя вы ее роль в жизни определили. " +
        "А в монастырь вас вряд ли заманят."
      break
    case 3:
      title = "Сомневающийся"
      description = "«Сомневающийся» — живущий, как и большинство людей, абсолютно материальными ценностями, " +
        "но иногда такой человек нет-нет, да и задумывается о чем-то более высшем."
      break
    case 4:
      title = "Развитая духовность"
      description = "«Развитая духовность» — преобладание духовных ценностей в таком человеке притягивает к нему " +
        "братьев по разуму. Вместе они начинают оказывать влияние на окружающих закоренелых материалистов."
      break
    case 5:
      title = "Высшая духовность"
      description = "«Высшая духовность» — примером чистоты и высокой направленности своих помыслов может являться " +
        "человек с 5 цифрами в этой диагонали. Как ни странно, но есть немало примеров бизнесменов и общественных " +
        "деятелей с этим знаком. И это говорит о том, что они делают свое дело далеко не просто ради денег, но " +
        "и чего-то более высшего."
      break
    default:
      title = "Фанатик"
      description = "«Фанатик» — это уже не духовность, а настощий фанатизм. Усугубляется он попытками затащить в " +
        "«свой монастырь» и научить правильно жить всех вокруг, а также пренебрежением к «низменным» реальным вещам " +
        "во имя высоких целей. Одним словом, такой человек может просто утратить связь с реальностью в погоне за " +
        "своими неведомыми высокими идеалами."
      break
  }
  
  return {
    isMain: false,
    digits: digits,
    digitCount: digitCount,
    
    title: title,
    description: description,
  }
}

const getTemperamentTrait = (rows: number[][]): PythagoreanSquareTraitInfo => {
  const digits = [3, 5, 7]
  const digitCount = getDigitsCount(rows, digits)
  
  let title;
  let description;
  switch (digitCount) {
    case 0:
      title = "Очень холодный"
      description = "«Очень холодный» — человек индифферентен к физической близости. С одной стороны, это неплохо, " +
        "что он ищет себе партнера ради не удовлетворения. С другой же, ему подходят лишь партнеры с его " +
        "темпераментом или с темпераментом 1."
        // + " Окружающие могут казаться ему озабоченными. Но бывает и так, что он " +
        // "сам старается казаться таким, следуя ожиданиям общества."
      break
    case 1:
      title = "Холодный"
      description = "«Холодный» — человек с 1 не против физической любви, но и не озабочен ей."
        // + " Еще человек с таким " +
        // "темпераментом бывает не слишком разборчив в партнерах и склонен к изменам, т.к. делает это ради своего " +
        // "самоутверждения. Мужчины с таким темпераментов часто хотят казаться Дон Жуанами в глазах друзей и " +
        // "противоположного пола, преувеличивая свои любовные победы."
      break
    case 2:
      title = "Нормальный"
      description = "«Нормальный» — нормальный темперамент подойдет большинству других темпераментов."
        // + " Но есть " +
        // "склонность к походам налево, т.к. сам носитель этого темперамента не до конца уверен в своих силах и " +
        // "мечтал бы о самоутверждении на любовном фронте."
      break
    case 3:
      title = "Импульсивный"
      description = "«Импульсивный» — этот темперамент может подстроиться практически под любого партнера: умерить " +
        "свой пыл с партнером, чей темперамент ниже, и, наоборот, быть более горячим с тем, чей темперамент выше."
        // + " Если партнера временно нет, то он может кинуться в беспорядочные связи. Часто такой темперамент старается " +
        // "казаться очень горячим в глазах противоположного пола."
      break
    case 4:
      title = "Теплый"
      description = "«Теплый» — необходимость в близости для такого темперамента высока, поэтому, если партнера нет, " +
        "то прикладывает все усилия к его поиску."
        // + " Однако и подходят ему лучше всего только темпераменты 3 и 5. С " +
        // "ними он раскрывается по-настоящему, заботясь не только о своем удовлетворении. Считается достаточно верным."
      break
    case 5:
      title = "Горячий"
      description = "«Горячий» — этот темперамент не мыслит себя без физической любви и требует ее много — для него " +
        "это часть самовыражения. При этом для него важно, чтобы и партнер был таким же горячим, тогда отдача будет " +
        "полной."
        // + " Но он не делает это ради самоутверждения, меняя партнеров, как перчатки, и рассказывая друзьям " +
        // "истории о своих победах. Напротив, горячие темпераменты внешне кажутся даже немного холодными. Весь их " +
        // "пыл проявляется лишь с партнером."
      break
    default:
      // todo Перегретый?
      title = "Слишком горяч"
      description = "«Слишком горяч» — человек с таким темпераментом кажется все время неудовлетворенным, даже если " +
        "только что покинул постель. Иногда он даже отказывается вовсе от близости, теряя к этому всякий интерес, " +
        "проявляя внешнюю холодность. А иногда, наоборот, в поисках разнообразия он теряет смысл и меру. Это " +
        "несбалансированный темперамент, знающий только крайности. Несмотря на это, при наличии одного партнера " +
        "обычно верен ему."
      break
  }
  
  return {
    isMain: false,
    digits: digits,
    digitCount: digitCount,
    
    title: title,
    description: description,
  }
}

//#endregion