

















import {defineComponent, PropType} from "@vue/composition-api";
import {ZodiacSignResult} from "../../../../backend/packages/calc/result/logic/models";
import useHoroscopeUtils from "@/utils/useHoroscopeUtils";
import Helpers from "@/utils/helpers";

export default defineComponent({
  props: {
    result: { type: Object as PropType<ZodiacSignResult>, required: true },
    roleText: String,
    female: Boolean,
  },
  setup() {
    const { getZodiacSignName, getZodiacSignIconClass, getZodiacSignElementName } = useHoroscopeUtils()

    return {
      helpers: Helpers,

      getZodiacSignName,
      getZodiacSignIconClass,
      getZodiacSignElementName,
    }
  }
})
